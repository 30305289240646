import { SEO } from "gatsby-theme-core";
import { Layout } from "gatsby-theme-layout";
import { NotFound } from "gatsby-theme-container";

const ErrorNotFound = () => (
    <Layout>
        <SEO pageName="404" />
        <NotFound />
    </Layout>
);

export default ErrorNotFound;
